import React from 'react';
import { node, number, string } from 'prop-types';
import {
  SectionWrapper,
  SectionNumber,
  SectionTitle,
  SectionContent,
  SectionContentWithForcedNormalFontWeight,
  SectionAnnotation,
} from '@components/Section/style';

const Section = ({
  id,
  index,
  title,
  content,
  children,
  className,
  annotation,
  shouldForceNormalFontWeight,
}) => (
  <SectionWrapper id={id} className={className}>
    <div>
      {index && <SectionNumber>{String(index).padStart(2, '0')}</SectionNumber>}
      {title && <SectionTitle>{title}</SectionTitle>}
    </div>
    {content &&
      (shouldForceNormalFontWeight ? (
        <SectionContentWithForcedNormalFontWeight
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : (
        <SectionContent dangerouslySetInnerHTML={{ __html: content }} />
      ))}
    {children}
    {annotation && (
      <SectionAnnotation dangerouslySetInnerHTML={{ __html: annotation }} />
    )}
  </SectionWrapper>
);

Section.defaultProps = {
  id: undefined,
  index: undefined,
  title: undefined,
  content: undefined,
  children: undefined,
};

Section.propTypes = {
  id: string,
  index: number,
  title: string,
  content: string,
  children: node,
};

export default Section;
