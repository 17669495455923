/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Waypoint } from 'react-waypoint';
import { Helmet } from 'react-helmet';
import parseSearch from '@utils/parseSearch';
import inIframe from '@utils/inIframe';

import Section from '@components/Section';
import Accordion from '@components/Accordion';
import AccordionItem from '@components/AccordionItem';
import {
  IntroductionSection,
  SavingsRow,
  MapKey,
  MapKeySection,
  BevFormDisabled,
} from '@containers/Home/style';
import CarsNav from '@containers/Home/components/CarsNav';
import CarsSlider from '@containers/Home/components/CarsSlider';
import JourneyTile, { TilesRow } from '@containers/Home/components/JourneyTile';
import SavingBox from '@containers/Home/components/SavingBox/SavingBox';
import CarCompare from '@containers/Home/components/CarCompare';
import ChargingPointsForm from '@containers/Home/components/ChargingPointsForm';
import Map from '@components/Map';
import CarDetails from '@containers/Home/components/CarDetails';
import BevForm from '@containers/Home/components/BevForm';
import PhevForm from '@containers/Home/components/PhevForm';
import MarkerBlueIcon from '@components/Map/marker-blue.svg';
import MarkerBlackIcon from '@components/Map/marker-black.svg';
import MarkerGreyIcon from '@components/Map/marker-grey.svg';

import Banner, {
  BannersRow,
  BANNER_TYPES,
  BANNER_VARIANTS,
} from '@components/Banner';

import { SAVING_PERIODS } from '@root/stores/calculatorStore';
import { Container, Annotation, LinkButton } from '@styles/globalStyles';
import { PAGES } from '@routes';

import { KINDS } from '@root/stores/carsStore';

import { useParams, useLocation } from 'react-router-dom';

const Home = ({
  pagesStore: {
    homepage: {
      data: { description, headline, banners, annotation },
    },
    getPage,
    getSection,
  },
  carsStore: {
    activeKind,
    activeCar,
    getCars,
    showCarsNav,
    carDetailsIsVisible,
    setActiveCarFromMap,
    isLoaded,
    isActiveKindPhev,
    isActiveCarPhev,
  },
  calculatorStore: {
    journeyPlans,
    activePlan,
    selectPlan,
    phevData,
    bevData,
    bevForm,
  },
  retailerStore: { dealerNumber, isDealerSet, isRetailer },
  commonsStore: { isIrelandSite },
}) => {
  useEffect(() => {
    if (isDealerSet) {
      getPage({ page: PAGES.homepage, dealerNumber });
      getCars({ dealerNumber });
    }
  }, [isDealerSet]);

  const vehicleSelectRef = useRef(null);

  const [indicativeFuelSavings, setIndicativeFuelSavings] = useState(
    'your_indicative_fuel',
  );

  useEffect(() => {
    setIndicativeFuelSavings(
      isActiveKindPhev ? 'your_indicative_fuel_phev' : 'your_indicative_fuel',
    );
  }, [isActiveKindPhev]);

  let { model, iframe } = useParams();
  const { search } = useLocation();
  const parsed = parseSearch(search);

  if (parsed.model) {
    model = decodeURIComponent(parsed.model);
  }

  if (parsed.iframe) {
    iframe = decodeURIComponent(parsed.iframe);
  }

  if (iframe) {
    localStorage.setItem('iframe', iframe);
  }

  if (!activeCar && isLoaded && model) {
    setActiveCarFromMap(model);
  }

  const handleMapScroll = ({ currentPosition }) => {
    setTimeout(() => {
      if (currentPosition === 'above') {
        showCarsNav(false);
      } else {
        showCarsNav(true);
      }
    }, 1);
  };

  const handleScollUp = (event) => {
    event.preventDefault();
    vehicleSelectRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const appBannerData = banners.find(
    ({ bannerType }) => bannerType === BANNER_TYPES.WITH_IMAGE,
  );

  const promoBannerData = banners.find(
    ({ bannerType }) => bannerType === BANNER_TYPES.FULL_WIDTH,
  );

  const brochureBannerData = {
    ...banners.find(({ bannerType }) => bannerType === BANNER_TYPES.BROCHURE),
    buttonUrl: activeCar?.brochureLink,
  };

  useEffect(() => {
    localStorage.removeItem('iframe');
  }, []);

  const chargingYourVehicleSection = (
    <Container>
      <Section
        title={
          getSection(
            'homepage',
            isActiveKindPhev ? 'charging_plugin_hybrid' : 'charging_electric',
          ).title
        }
        content={getSection('homepage', 'charging_plugin_hybrid').description}
      >
        <ChargingPointsForm />
        <Map />
        <MapKey>
          <MapKeySection>
            <img src={MarkerBlueIcon} alt="Rapid charger icon" />
            Rapid (43+ kW)
          </MapKeySection>
          <MapKeySection>
            <img src={MarkerBlackIcon} alt="Fast charger icon" />
            Fast (7-22 kW)
          </MapKeySection>
          <MapKeySection>
            <img src={MarkerGreyIcon} alt="Slow charger icon" />
            Slow (3-6 kW)
          </MapKeySection>
        </MapKey>
      </Section>
    </Container>
  );

  const carSelectorAndCalculator = (
    <>
      <Container ref={vehicleSelectRef}>
        <Section
          id="section-01"
          index={1}
          title={getSection('homepage', 'calculate_your_potential').title}
          content={
            getSection('homepage', 'calculate_your_potential').description
          }
        />
      </Container>
      <CarsNav />
      {carDetailsIsVisible && (
        <Container>
          <CarDetails
            tooltipContent={
              getSection('homepage', 'calculate_your_potential')
                .additionalDescription
            }
          />
        </Container>
      )}
      {!carDetailsIsVisible && <CarsSlider />}
      <Container>
        {activeKind === KINDS.BEV ? (
          <Section
            index={2}
            title={getSection('homepage', 'your_journey').title}
            content={
              getSection('homepage', 'your_journey').additionalDescription
            }
          >
            {activeCar ? (
              <BevForm />
            ) : (
              <>
                <LinkButton variant="primary" href="#" onClick={handleScollUp}>
                  Select a model to continue
                </LinkButton>
                <BevFormDisabled>
                  <BevForm />
                </BevFormDisabled>
              </>
            )}
          </Section>
        ) : (
          <Section
            index={2}
            title={getSection('homepage', 'your_journey').title}
            content={getSection('homepage', 'your_journey').description}
          >
            <TilesRow>
              {journeyPlans.map((plan) => (
                <JourneyTile
                  key={`plan-box__${plan.id}`}
                  plan={plan}
                  selected={plan.id === activePlan}
                  onClick={() => selectPlan(plan.id)}
                />
              ))}
            </TilesRow>
          </Section>
        )}
        <Section
          index={3}
          title={getSection('homepage', indicativeFuelSavings).title}
          content={
            isActiveKindPhev
              ? activeCar
                ? `<p style="font-size: 18px">BASED ON A BMW ${activeCar?.basedOnName}</p>`
                : '<p>Please choose BMW model first.</p>'
              : ''
          }
          annotation={getSection('homepage', indicativeFuelSavings).annotation}
        >
          {isActiveKindPhev ? (
            <>
              <PhevForm />
              <SavingsRow>
                <SavingBox
                  title="Monthly savings in first year"
                  value={phevData.monthlySavings}
                />
                <SavingBox
                  title="Savings in first year"
                  value={phevData.firstYearMonthlySavings}
                />
                <SavingBox
                  title="Savings in three years"
                  value={phevData.threeYearsMonthlySavings}
                />
              </SavingsRow>
            </>
          ) : (
            <SavingsRow>
              <SavingBox
                title="Indicative charging cost"
                value={bevData.chargingCost}
              />
              <SavingBox
                title="Indicative fuel cost"
                value={bevData.fuelCost}
              />
              <SavingBox
                title={
                  bevForm.period === SAVING_PERIODS.ANNUAL
                    ? 'Indicative first year savings'
                    : 'Indicative monthly savings'
                }
                value={bevData.saved}
              />
            </SavingsRow>
          )}
        </Section>
        {!isIrelandSite && !isActiveKindPhev && (
          <Section>
            <Accordion>
              <AccordionItem
                title={getSection('homepage', 'home_charging_explained').title}
                content={
                  getSection('homepage', 'home_charging_explained').description
                }
              />
              <AccordionItem
                title={
                  <>
                    BMW Charging<sup>&dagger;</sup> Explained
                  </>
                }
                content={
                  getSection('homepage', 'charging_explained').description
                }
              />
              <AccordionItem
                title={getSection('homepage', 'smart_tariff_explained').title}
                content={
                  getSection('homepage', 'smart_tariff_explained').description
                }
              />
            </Accordion>
          </Section>
        )}
        {isActiveKindPhev && (
          <Section
            index={4}
            title={getSection('homepage', 'how_compares').title}
            content={
              isActiveCarPhev
                ? getSection('homepage', 'how_compares').description?.replace(
                    '{{model}}',
                    activeCar.fullName,
                  )
                : 'Your illustrative results will be based on a comparison between the two BMW models. Please choose BMW model first.'
            }
          >
            {isActiveCarPhev && <CarCompare />}
          </Section>
        )}
      </Container>
      <Waypoint
        onPositionChange={handleMapScroll}
        scrollableAncestor={window}
        topOffset={114}
      >
        <div />
      </Waypoint>
      {promoBannerData && (
        <Banner {...promoBannerData} variant={BANNER_VARIANTS.PROMO} />
      )}
    </>
  );

  const allContent = (
    <>
      <Helmet>
        <title>BMW Electrified</title>
      </Helmet>
      <Container>
        <IntroductionSection
          title={headline}
          content={description}
          shouldForceNormalFontWeight={!isRetailer}
        />
      </Container>

      {carSelectorAndCalculator}

      {chargingYourVehicleSection}

      <>
        {activeCar?.brochureLink ? (
          <Container>
            <BannersRow>
              {appBannerData && (
                <Banner {...appBannerData} variant={BANNER_VARIANTS.COLUMN} />
              )}
              {brochureBannerData && (
                <Banner
                  {...brochureBannerData}
                  variant={BANNER_VARIANTS.COLUMN}
                />
              )}
            </BannersRow>
          </Container>
        ) : (
          appBannerData && <Banner {...appBannerData} />
        )}
        {annotation && (
          <Container>
            <Annotation dangerouslySetInnerHTML={{ __html: annotation }} />
          </Container>
        )}
      </>
    </>
  );

  const iframeType = localStorage.getItem('iframe');

  if (inIframe() && iframeType?.toLowerCase() === 'map') {
    return chargingYourVehicleSection;
  }

  if (inIframe() && iframeType?.toLowerCase() === 'calc') {
    return carSelectorAndCalculator;
  }

  return allContent;
};

Home.propTypes = {
  pagesStore: PropTypes.shape({
    home: PropTypes.shape({
      data: PropTypes.object,
      isLoading: PropTypes.bool,
    }),
    getPage: PropTypes.func,
  }).isRequired,
  carsStore: PropTypes.shape({
    showCarsNav: PropTypes.func,
    carDetailsIsVisible: PropTypes.bool,
  }).isRequired,
  calculatorStore: PropTypes.object.isRequired,
  retailerStore: PropTypes.object.isRequired,
};

export default inject(
  'pagesStore',
  'carsStore',
  'calculatorStore',
  'retailerStore',
  'commonsStore',
)(observer(Home));
