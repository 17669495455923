import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { ThemeProvider } from '@emotion/react';
import { Router, Switch } from 'react-router-dom';
import { history } from '@app/history';

import GlobalStyle from '@styles/globalStyles';

import theme from '@styles/theme';
import Route from '@components/Route';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import { get } from 'lodash';
import RetailerSite from '@containers/RetailerSite';
import NationalSite from '@containers/NationalSite';
import { RETAILER_ROUTE } from '@routes';
import debounce from '@utils/debounce';
import inIframe from '@utils/inIframe';

import useRuntimeEnvironmentVariables from '@hooks/useRuntimeEnvironmentVariables';

const App = ({
  commonsStore: { getCommons, isIrelandSite },
  retailerStore: { dealerNumber, setDealerNumber, isDealerSet },
}) => {
  useEffect(() => {
    if (isDealerSet) {
      getCommons({
        dealerNumber,
        onError: (error) => {
          if (error.response.status === 404) history.replace('/');
        },
      });
    }
  }, [dealerNumber]);

  useEffect(
    () =>
      history.listen(() => {
        loadInGoogleScript();
      }),
    [history],
  );

  const routeUpdate = (params) => {
    setDealerNumber(get(params, 'computedMatch.params.dealerNumber', ''));
  };

  const runtimeEnvironmentVariables = useRuntimeEnvironmentVariables();

  return (
    <ThemeProvider theme={theme}>
      {!inIframe() && (
        <div>
          <Helmet>
            <script
              type="text/javascript"
              src={runtimeEnvironmentVariables.REACT_APP_EPAAS_URL}
              onLoad={`epaas.api.initialize({tenant: '${
                runtimeEnvironmentVariables.REACT_APP_EPAAS_TENANT
              }', locale: 'en_${isIrelandSite ? 'IE' : 'GB'}'})`}
            />
          </Helmet>
        </div>
      )}
      <GlobalStyle />
      <Router history={history}>
        <Switch>
          <Route path={RETAILER_ROUTE} noLayout routeUpdate={routeUpdate}>
            <RetailerSite />
          </Route>
          <Route path="/" routeUpdate={routeUpdate} noLayout>
            <NationalSite />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

function loadInGoogleScript() {
  // eslint-disable-next-line no-undef
  if (epaas.api.isUsageAllowed('GoogleAnalytics')) {
    ReactGA.initialize(
      useRuntimeEnvironmentVariables().REACT_APP_GA4_MEASUREMENT_ID,
    );
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  (window.epaas || window).addEventListener(
    'consentcontroller.api.initialized',
    () => {
      loadInGoogleScript();
      // eslint-disable-next-line no-undef
      epaas.api.registerOnUserConsentChange(
        'GoogleAnalytics',
        loadInGoogleScript,
      );
    },
  );
});

function calculateHeight(event) {
  const offset = 85;
  let height = '';

  if (event) {
    height =
      event.type === 'load'
        ? document.documentElement.scrollHeight
        : document.body.clientHeight;
  } else {
    height = document.documentElement.scrollHeight;
  }

  window.parent.postMessage({ type: 'resize', value: height + offset }, '*');
}

if (inIframe()) {
  const debouncedHandler = debounce(calculateHeight, 500);
  window.addEventListener('load', debouncedHandler);
  window.addEventListener('resize', debouncedHandler);
  window.calculateHeight = calculateHeight;
}

App.propTypes = {
  commonsStore: PropTypes.shape({
    getCommons: PropTypes.func,
    isIrelandSite: PropTypes.bool,
  }).isRequired,
};

export default inject('commonsStore', 'retailerStore')(observer(App));
