import { makeAutoObservable, runInAction } from 'mobx';
import MarkerClusterer from '@googlemaps/markerclustererplus';

import { getCenter, getDistance } from 'geolib';
import { API, APIRoutes } from '@app/api';

import MarkerBlueIcon from '@components/Map/marker-blue.svg';
import MarkerBlackIcon from '@components/Map/marker-black.svg';
import MarkerGreyIcon from '@components/Map/marker-grey.svg';
import MarkerBlackAndGreyIcon from '@components/Map/marker-black-grey.svg';
import MarkerBlueAndBlackIcon from '@components/Map/marker-blue-black.svg';
import MarkerBlueAndGreyIcon from '@components/Map/marker-blue-grey.svg';
import MarkerBlueBlackAndGrey from '@components/Map/marker-blue-black-grey.svg';

import commonsStore from '@stores/commonsStore';
import inIframe from '@utils/inIframe';

const milesToM = (distance) => {
  const factor = commonsStore.isIrelandSite ? 1 : 1.609344;
  return distance * 1000 * factor;
};

const mapDay = (day) => {
  const valuesDict = {
    mo: 'Mon',
    tu: 'Tues',
    we: 'Weds',
    th: 'Thurs',
    fr: 'Fri',
    sa: 'Sat',
    su: 'Sun',
  };

  return valuesDict[day] || day;
};

const initMapRetryBackoffInitialDelay = 100;
const initMapRetryBackoffMultiplier = 1.5;

export class MapDataStore {
  constructor() {
    makeAutoObservable(this);
  }

  center = { lat: 51.502059, lng: -0.140234 };

  startZip = '';

  startCoord = null;

  endZip = '';

  endCoord = null;

  minRadiusValue = commonsStore.isIrelandSite ? 10 : 5;

  maxRadiusValue = commonsStore.isIrelandSite ? 50 : 30;

  radiusValue = this.minRadiusValue;

  radiusMeters = milesToM(this.radiusValue);

  stepCount = commonsStore.isIrelandSite ? 4 : 5;

  markers = [];

  data = [];

  zoom = 10;

  setDirectionResponse = () => {};

  milesToM = milesToM;

  inProgress = false;

  chargingPointsInfo = {};

  showMapOnMobile = false;

  markerClusterer = null;

  map = null;

  infoWindow = null;

  directionsService = new google.maps.DirectionsService();

  directionsRenderer = new google.maps.DirectionsRenderer();

  setRadius = (value) => {
    this.radiusValue = value;
    this.radiusMeters = milesToM(value);
  };

  setValue = (key, value) => {
    this[key] = value;
  };

  initMap = async (retryBackoffDelay = initMapRetryBackoffInitialDelay) => {
    if (!commonsStore.mapDataIsReady) {
      setTimeout(
        () => this.initMap(retryBackoffDelay * initMapRetryBackoffMultiplier),
        retryBackoffDelay,
      );

      return;
    }
    const retailerPostcode = commonsStore.isIrelandSite
      ? 'Dublin Ireland'
      : commonsStore.retailer?.postcode;

    if (retailerPostcode) {
      this.center = await this._getCoordsFromZipCodes(retailerPostcode);
    }

    this.map = new google.maps.Map(document.getElementById('map'), {
      center: this.center,
      zoom: this.zoom,
      fullscreenControl: false,
    });
    if (this.data.length) {
      this.showAllMarkers();
    }
  };

  resetForm = () => {
    this.startZip = '';
    this.startCoord = null;
    this.endZip = '';
    this.endCoord = null;
    this.radiusValue = this.minRadiusValue;
    this.radiusMeters = milesToM(this.radiusValue);
    this.showMapOnMobile = false;
    this.markers = [];
  };

  _getCoordsFromZipCodes = async (zip) => {
    const geocoder = new google.maps.Geocoder();
    let center = {};

    const address = `${zip},${commonsStore.isIrelandSite ? 'IE' : 'UK'}`;
    await geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        const { location } = results[0].geometry;
        center = { lat: location.lat(), lng: location.lng() };
      } else {
        console.error(`Geocode was not successful: ${status}`); // eslint-disable-line no-console
      }
    });
    return center;
  };

  swapZipCodes = () => {
    const tmp = this.startZip;
    this.startZip = this.endZip;
    this.endZip = tmp;
  };

  getChargingPointInfo = async (chargingPointId) => {
    if (this.chargingPointsInfo[chargingPointId]) {
      return;
    }

    try {
      const {
        data: { chargingPoint },
      } = await API(APIRoutes.CHARGING_POINT_INFO(chargingPointId));

      runInAction(() => {
        this.chargingPointsInfo[chargingPointId] = chargingPoint;
      });
    } catch (error) {
      console.debug(error); // eslint-disable-line no-console
    }
  };

  filterMarkers = async () => {
    this.clearMarkers();

    try {
      this.startCoord = this.startZip.length
        ? await this._getCoordsFromZipCodes(this.startZip)
        : null;

      this.endCoord = this.endZip.length
        ? await this._getCoordsFromZipCodes(this.endZip)
        : null;

      if (this.startCoord !== null && this.endCoord !== null) {
        const { latitude: lat, longitude: lng } = getCenter([
          { lat: this.startCoord.lat, lng: this.startCoord.lng },
          { lat: this.endCoord.lat, lng: this.endCoord.lng },
        ]);

        this.center = { lat, lng };
        this.radiusMeters = getDistance(this.center, this.startCoord);

        this.calcRoute();
      } else if (this.startCoord !== null) {
        this.center = this.startCoord;
        this.directionsRenderer.setMap(null);
      }

      runInAction(() => {
        this.data.forEach((point) => {
          if (getDistance(point.position, this.center) < this.radiusMeters) {
            this.markers.push(this.createMarker(point));
          }
        });
        const bounds = new google.maps.LatLngBounds();

        this.markers.forEach((marker) => {
          bounds.extend(marker.getPosition());
        });

        this.map.fitBounds(bounds);

        this.zoom = this.map.getZoom();

        this.createMarkerClusterer();

        google.maps.event.addListenerOnce(this.map, 'idle', () => {
          if (this.markerClusterer) {
            this.markerClusterer.repaint();
          }
        });

        document.getElementById('map').scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      });
      this.map.setCenter(this.center);
    } catch (error) {
      console.debug(error); // eslint-disable-line no-console
    }
  };

  calcRoute = () => {
    this.directionsRenderer.setMap(this.map);

    const request = {
      origin: this.startCoord,
      destination: this.endCoord,
      travelMode: 'DRIVING',
    };

    this.directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        this.directionsRenderer.setDirections(result);
      }
    });
  };

  createMarker = (point) => {
    const { fast, rapid, slow } = point;

    let iconUrl = null;

    if (rapid && !fast && !slow) iconUrl = MarkerBlueIcon;
    if (!rapid && fast && !slow) iconUrl = MarkerBlackIcon;
    if (!rapid && !fast && slow) iconUrl = MarkerGreyIcon;

    if (rapid && fast && !slow) iconUrl = MarkerBlueAndBlackIcon;
    if (rapid && !fast && slow) iconUrl = MarkerBlueAndGreyIcon;
    if (!rapid && fast && slow) iconUrl = MarkerBlackAndGreyIcon;

    if (rapid && fast && slow) iconUrl = MarkerBlueBlackAndGrey;

    const icon = {
      url: iconUrl,
      scaledSize: new google.maps.Size(28, 40),
    };

    const marker = new google.maps.Marker({
      position: point.position,
      map: this.map,
      icon,
    });

    marker.addListener('click', async () => {
      if (this.infoWindow) {
        this.infoWindow.close();
      }
      await this.getChargingPointInfo(point.pid);
      const pointDetails = this.chargingPointsInfo[point.pid];

      let accessibility = '';
      if (pointDetails.openingTimes) {
        const times = pointDetails.openingTimes
          .replace(/(\\r\\n|\\n|\\r)/gm, ';')
          .split(';');

        const openingHours = [];

        times.forEach((time) => {
          const [day, hours] = time.split(': ');
          openingHours.push(`<strong>${mapDay(day)}</strong>: ${hours}`);
        });

        accessibility = `<div class="point-details__accessibility">
          <strong>Opening times:</strong>
          <div>${openingHours.join('<br />')}<div>
        </div>`;
      }
      if (pointDetails.accessible24Hours) {
        accessibility = `<div class="point-details__accessibility">
          <strong>Opening times:</strong>
          24 hours
        </div>`;
      }

      const contentString = `<div class="point-details">
        <h2>${pointDetails.chargeDeviceName}</h2>
        <h3>${pointDetails.fullAddress}</h3>
        <ul className="point-details__list">
          ${
            pointDetails.locationType
              ? `<li><strong>Location type:</strong> ${pointDetails.locationType}</li>`
              : ''
          }
          <li><strong>Networks:</strong> ${pointDetails.networks}</li>
          ${
            pointDetails.devicesNo > 1
              ? `<li><strong>Number of devices:</strong> ${pointDetails.devicesNo}</li>`
              : ''
          }
        </ul>
        ${accessibility}
      </div>`;

      runInAction(() => {
        this.infoWindow = new google.maps.InfoWindow({
          content: contentString,
        });
        this.infoWindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false,
        });
      });
    });

    return marker;
  };

  showAllMarkers = () => {
    this.markers = this.data.map(this.createMarker);
    this.createMarkerClusterer();
    if (inIframe()) window.calculateHeight();
  };

  createMarkerClusterer = () => {
    this.markerClusterer = new MarkerClusterer(this.map, this.markers, {
      clusterClass: 'map-cluster-icon',
      zoom: this.zoom,
      minimumClusterSize: 4,
      batchSize: 5000,
    });
  };

  clearMarkers = () => {
    this.markers.forEach((marker) => marker.setMap(null));
    this.markers = [];
    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers();
      this.markerClusterer = null;
    }
  };

  getData = async () => {
    if (this.inProgress || this.data.length > 0) return;

    this.inProgress = true;

    try {
      const {
        data: { chargingPoints },
      } = await API(APIRoutes.CHARGING_POINTS);
      runInAction(() => {
        this.data = chargingPoints;
      });
      this.showAllMarkers();
    } catch (error) {
      console.debug(error); // eslint-disable-line no-console
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  };

  setMapOnMobile = (isMobile) => {
    this.showMapOnMobile = isMobile;
  };
}

export default new MapDataStore();
