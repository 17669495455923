import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import { NAVBAR_ITEMS, routeFor, PAGES } from '@app/routes';
import {
  Wrapper,
  Close,
  Content,
  Logo,
  Navigation,
  LinkItem,
  Burger,
  BurgerLine,
  RetailerName,
} from '@components/Header/components/Navbar/style';

import logoLight from '@assets/images/logo/bmw.svg';
import logoDark from '@assets/images/logo/bmw-dark.svg';
import { inject, observer } from 'mobx-react';
import commonsStore from '@root/stores/commonsStore';

const Navbar = ({
  theme,
  hidePageElements,
  retailerStore: { dealerNumber },
}) => {
  const [mobileMenuVisible, setMenuVisibility] = useState(false);

  const handleLinkClick = (callback) => {
    if (mobileMenuVisible) {
      setMenuVisibility(false);
    }
    if (callback instanceof Function) {
      callback();
    }
  };

  if (hidePageElements) return false;

  return (
    <Wrapper colorScheme={theme} navigationIsVisible>
      <Content>
        <Burger
          colorScheme={theme}
          type="button"
          onClick={() => setMenuVisibility(true)}
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            xmlSpace="preserve"
          >
            <BurgerLine className="burger-line" />
            <BurgerLine className="burger-line" />
            <BurgerLine className="burger-line" />
          </svg>
        </Burger>
        <Navigation colorScheme={theme} mobileVisible={mobileMenuVisible}>
          <Flex justifyContent="flex-end" mt={4} mb={4}>
            <Close type="button" onClick={() => setMenuVisibility(false)} />
          </Flex>
          {NAVBAR_ITEMS.map((item) => (
            <LinkItem
              exact
              key={`navbar-item_${item.label}`}
              to={item.route(dealerNumber)}
              activeClassName="active"
              colorScheme={theme}
              onClick={() => handleLinkClick(item.callback)}
            >
              <span>{item.label}</span>
            </LinkItem>
          ))}
        </Navigation>
        <a href={routeFor(PAGES.homepage)}>
          <Logo colorScheme={theme}>
            {commonsStore.retailer?.name ? (
              <RetailerName>{commonsStore.retailer.name}</RetailerName>
            ) : null}
            <img src={logoLight} alt="BMW" className="light" />
            <img src={logoDark} alt="BMW" className="dark" />
          </Logo>
        </a>
      </Content>
    </Wrapper>
  );
};

Navbar.defaultProps = {
  theme: 'light',
  hidePageElements: false,
};

Navbar.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  hidePageElements: PropTypes.bool,
};

export default inject('commonsStore', 'retailerStore')(observer(Navbar));
