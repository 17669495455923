import React from 'react';
import PropTypes from 'prop-types';
import Header from '@components/Header';
import Footer from '@components/Footer';
import IconLinks from '@components/IconLinks';
import { Main } from '@components/Layout/style';
import inIframe from '@utils/inIframe';

const Layout = ({ page, children }) => (
  <div>
    {!inIframe() && <Header {...{ page }} />}
    <Main>{children}</Main>
    {!inIframe() && <IconLinks />}
    {!inIframe() && <Footer />}
  </div>
);

Layout.defaultProps = {
  page: '',
};

Layout.propTypes = {
  page: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Layout;
