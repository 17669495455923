import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import inIframe from '@utils/inIframe';

export const TabsRow = styled.div`
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-around;
  flex-direction: row;

  @media ${breakpoint.md} {
    justify-content: center;
  }
`;

export const Tab = styled.div`
  font-size: 14px;
  line-height: 1;
  padding: 16px;
  border-bottom: 4px solid
    ${({ active, theme }) => (active ? theme.colors.primary : 'transparent')};
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${(props) => (props.active ? '#262626' : '#666666')};
  cursor: pointer;

  @media ${breakpoint.md} {
    margin: 0 32px;
  }
`;

export const TabsContainer = styled.div`
  ${inIframe() ? `display: none;` : ''}
`;
